import React from "react";
import training from "../../content/landingpage/training.yaml";
import professorImg from "../../img/professor.svg";
import {interpretLineJump} from "../../helpers/interpretLineJumpsInYaml";

export const Training = () => {
    return (
        <>
            <section id="training" className="training-section ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <p className="color-secondary">
                                <strong>{training.category}</strong>
                            </p>
                            <h2 dangerouslySetInnerHTML=
                                    {{__html: interpretLineJump(training.title)}}/>
                            <p dangerouslySetInnerHTML=
                                   {{__html: interpretLineJump(training.description)}}/>
                            <div className="row mt-5">
                                {(training.caracteristics || []).map((data, index) => {
                                    return (
                                        <div className="col-md-6 single-feature mb-4" key={index}>
                                            <div className="d-flex align-items-center mb-2">
                                                <span className={data.item.icon}/>
                                                <h5 className="mb-0">{data.item.title}</h5>
                                            </div>
                                            <p dangerouslySetInnerHTML=
                                                   {{__html: interpretLineJump(data.item.description)}}/>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="service-content-left d-none d-lg-block">
                                <img
                                    src={professorImg}
                                    alt="Formateur"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};